<style lang="scss">
.task {
  @apply font-druk_text_cy;
  border: 1px solid #313131;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 16px 44px;
  border-radius: 15px;
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &:focus {
    outline: none;
  }
  &:disabled {
    @apply cursor-not-allowed;
  }
  &__wrap {
    height: 100%;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 139px;
    &-descr {
      & p {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 8px;
      }
      & span {
        font-size: 16px;
        line-height: 20px;
        color: #00c1f5;
      }
    }
  }
  &__timer {
    & p {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 4px;
      text-align: right;
    }
    &-block {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    &-start {
      order: 1;
      margin-bottom: 8px;
    }
    &-done {
      @apply font-druk_text_cy;
      order: 2;
      display: flex;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #00cdb6;
      padding: 4px 8px;
      border: 1px solid rgba(0, 205, 182, 0.32);
      box-sizing: border-box;
      border-radius: 4px;
      & span {
        color: #fff;
      }
      & svg {
        margin-left: 12px;
      }
    }
    &-undone {
      color: rgba(255, 255, 255, 0.75);
      border: 1px solid rgba(255, 255, 255, 0.32);
    }
  }
  &__footer {
    @apply text-left;
    max-width: 400px;
    &-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 8px;
    }
    &-subtitle {
      @apply font-graphik_lv_regular;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .blur-main {
    background-color: rgba(0, 0, 0, 0.75);
    background-image: url('/images/final-label-main.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto 113%;
    backdrop-filter: blur(4px);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 15px;
  }

  .blur {
    background-color: rgba(0, 0, 0, 0.75);
    background-image: url('/images/final-label.png');
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: blur(4px);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 15px;
  }
}
@screen lg {
  .task {
    &__timer {
      &-block {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
      &-start {
        order: 2;
      }
      &-done {
        order: 1;
        margin-right: 8px;
      }
    }
  }
}
</style>

<template>
  <button
    class="task"
    :style="{
      backgroundImage: `linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(0, 0, 0, 0.7) 38.38%, rgba(0, 0, 0, 0.9) 93.68%), url('${image}')`,
    }"
    :disabled="!isAvailable"
    @click="openTask"
  >
    <div v-if="!isAvailable" :class="mainTask ? 'blur-main' : 'blur'"></div>
    <div class="task__header">
      <div class="task__header-descr">
        <p class="text-left">Тип задачи</p>
        <span v-if="mainTask">Основная</span>
        <span v-else>Дополнительная</span>
      </div>
      <div v-if="isAvailable && user.is_finalist" class="task__timer">
        <p>{{ isEnded ? 'Прием работ завершен' : 'Осталось времени' }}</p>
        <div class="task__timer-block">
          <div
            class="task__timer-done"
            :class="{ 'task__timer-undone': !user.essay_file }"
          >
            <p v-if="mainTask && result">
              <span style="color: #00cdb6">Результат:&nbsp;&nbsp;</span>
              <span>{{ result }}</span>
            </p>
            <p v-else-if="user.essay_file && !mainTask">Решение отправлено</p>
            <p v-else class="task__timer-done--white">Решение не отправлено</p>
          </div>
          <div v-if="!isEnded" class="task__timer-start">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="task__footer">
      <p class="task__footer-title">{{ title }}</p>
      <p v-if="descr" class="task__footer-subtitle">
        {{ descr }}
      </p>
    </div>
  </button>
</template>

<script>
import IconDone from '@/components/Icons/IconTimerDone.vue'
export default {
  name: 'Task',
  components: {
    IconDone,
  },
  data() {
    return {
      tickerText: 'доступно с 13:00 по МСК',
    }
  },
  props: {
    status: { type: Object },
    isAvailable: {
      type: Boolean,
      default: false,
    },
    mainTask: { type: Boolean },
    image: { type: String },
    user: { type: Object },
    taskId: { type: String },
    result: { type: String },
    descr: { type: String },
    title: { type: String },
    isEnded: { type: Boolean },
  },
  methods: {
    openTask() {
      this.$router.push({ name: 'IssuesItem', params: { id: this.taskId } })
    },
  },
  mounted() {},
}
</script>
