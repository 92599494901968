<style lang="scss">
.container {
  @apply -m-2 flex flex-wrap;
  margin-top: 32px;
}
</style>

<template>
  <div class="container">
    <issue-card v-for="item in issues" :key="item.id" v-bind="item" />
  </div>
</template>

<script>
import useGame from '@/composables/useGame'
import IssueCard from '@/components/IssueCard.vue'

export default {
  name: 'IssuesList',
  components: { IssueCard },
  data() {
    return {}
  },
  setup() {
    const { issues } = useGame()
    return { issues }
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
