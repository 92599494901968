<style lang="scss">
.small-timer {
  @apply whitespace-nowrap;
  border: 1px solid rgba(255, 255, 255, 0.32);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 78px;
}
</style>

<template>
  <div class="small-timer">{{ hours }} : {{ minutes }} : {{ seconds }}</div>
</template>

<script>
export default {
  name: 'small-timer',
  data() {
    return {
      date: Math.round(this.interval),
      currentTime: Math.round(this.interval),
      timer: null,
    }
  },
  computed: {
    days() {
      return Math.floor(this.currentTime / 86400)
    },
    hours() {
      return Math.floor(
        ((this.currentTime % 31536000) % 86400) / 3600 + this.days * 24
      )
        .toString()
        .padStart(2, '0')
    },
    minutes() {
      return Math.floor((((this.currentTime % 31536000) % 86400) % 3600) / 60)
        .toString()
        .padStart(2, '0')
    },
    seconds() {
      return ((((this.currentTime % 31536000) % 86400) % 3600) % 60)
        .toString()
        .padStart(2, '0')
      return `${numdays}:${numhours}:${numminutes}:${numseconds}`
    },
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime -= 1

        if (this.currentTime <= 0) {
          this.handleEndTime(this)
          this.stopTimer()
        }
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
  },
  mounted() {
    this.startTimer()
  },
  beforeDestroy() {
    this.stopTimer()
  },
  watch: {
    currentTime(time) {
      if (time === 0) {
        this.stopTimer()
      }
    },
    interval(val) {
      this.currentTime = val
    },
  },
  props: {
    interval: {
      type: Number,
    },
  },
}
</script>
