<style lang="scss">
.final {
  @apply text-left;
  padding-top: 40px;
  padding-bottom: 32px;
  &-block {
    background: linear-gradient(
      180deg,
      rgba(22, 22, 22, 0.76) 0%,
      #1e1e1e 100%
    );
    border: 1px solid #313131;
    box-sizing: border-box;
    border-radius: 8px;
    &-description {
      padding: 32px 18px;
    }
  }
  &__title {
    @apply font-druk_text_cy flex justify-between flex-col lg:flex-row;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #ffffff;
    margin-bottom: 49px;
  }
  &__section {
    &-first {
      width: 100%;
      margin-right: 16px;
    }
    &-second {
      width: 100%;
    }
  }
  &__prize {
    @apply font-druk_text_cy;
    color: #ffffff;
    margin-top: 60px;
    margin-bottom: 80px;
    &-title {
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      text-transform: uppercase;
      margin-bottom: 28px;
    }
    &-list {
      @apply font-graphik_lv_regular;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 15px;
      & p {
        &:first-child {
          width: 90px;
          text-align: left;
          margin-right: 38px;
          margin-bottom: 8px;
        }
      }
      & span {
        color: #00c1f5;
      }
    }
  }
  &__tabs {
    margin-bottom: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    &-item {
      @apply font-druk_text_cy;
      margin-right: 32px;
      font-size: 24px;
      line-height: 24px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.5);
      &:last-child {
        margin-right: 0;
      }
      &:focus {
        outline: none;
      }
      &.current {
        color: rgba(255, 255, 255, 1);
        padding-bottom: 11px;
        border-bottom: 2px solid rgba(1, 192, 242, 1);
      }
    }
  }
}
@screen lg {
  .final {
    &__section {
      display: flex;
      justify-content: space-between;
      &-first {
        width: 65%;
      }
      &-second {
        width: 35%;
      }
    }
    &__prize {
      &-title {
        font-size: 24px;
      }
      &-list {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 30px;
        & p {
          margin-right: 3px;
          margin-bottom: 0px;
          display: inline-block;
          &:first-child {
            width: 140px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="final__tabs text-left">
      <button
        v-for="item in tabs"
        :key="item"
        class="final__tabs-item"
        :class="{ current: item == currentTab }"
        @click="setTab(item)"
      >
        {{ item }}
      </button>
    </div>
    <div
      v-if="list && currentTab === 'Расписание'"
      class="landing-block shedule-block mb-4"
    >
      <shedule-table :list="list" />
      <tg-chat variant="left" />
    </div>
    <div v-else-if="currentTab === 'Задачи отборочного'" class="">
      <issues-list />
    </div>
    <div v-else-if="currentTab === 'Задачи финала'" class="">
      <final-tasks
        v-if="userStats"
        :finalStatus="finalStatus"
        :userStats="userStats"
        :user="user"
      />
    </div>
  </div>
</template>

<script>
import Description from '@/components/Final/Description.vue'
import SheduleTable from '@/components/SheduleTable.vue'
import TgChat from '@/components/TgChat.vue'
import useGame from '@/composables/useGame'
import useUser from '@/composables/useUser'
import { ref } from '@vue/composition-api'
import IssuesList from '@/components/IssuesList.vue'
import FinalTasks from '@/components/Final/FinalTasks.vue'

export default {
  name: 'FinalTabs',
  components: {
    SheduleTable,
    IssuesList,
    FinalTasks,
    TgChat,
  },
  data() {
    return {
      currentTab: this.list ? 'Расписание' : 'Задачи финала',
      tabs: this.list
        ? ['Расписание', 'Задачи отборочного', 'Задачи финала']
        : ['Задачи отборочного', 'Задачи финала'],
    }
  },
  setup(props, ctx) {
    const { finalStatus, loadUserStats } = useGame()
    const { userStats } = useGame()
    const { user, updateUser } = useUser()
    loadUserStats()
    updateUser()
    return {
      finalStatus,
      userStats,
      loadUserStats,
      user,
    }
  },
  props: {
    list: { type: Array },
  },
  created() {},
  mounted() {},
  methods: {
    setTab(item) {
      this.currentTab = item
    },
  },
  computed: {},
}
</script>
