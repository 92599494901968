<style lang="scss"></style>

<template>
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.99968 0.666687C4.08197 0.667838 3.18497 0.939546 2.42087 1.44783C1.65677 1.95611 1.0595 2.6784 0.703754 3.52436C0.348011 4.37032 0.249591 5.30239 0.420804 6.20399C0.592017 7.10559 1.02526 7.93669 1.66635 8.59335V14.6667C1.6667 14.7803 1.69608 14.892 1.75173 14.9911C1.80737 15.0902 1.88742 15.1734 1.98427 15.2328C2.08113 15.2923 2.19157 15.326 2.30511 15.3308C2.41866 15.3355 2.53153 15.3112 2.63302 15.26L4.99968 14.08L7.36635 15.26C7.45923 15.3076 7.56199 15.3327 7.66635 15.3334C7.79117 15.3338 7.91359 15.2991 8.01968 15.2334C8.11576 15.1733 8.19495 15.0898 8.24977 14.9906C8.30459 14.8915 8.33324 14.78 8.33302 14.6667V8.59335C8.97411 7.93669 9.40735 7.10559 9.57857 6.20399C9.74978 5.30239 9.65136 4.37032 9.29561 3.52436C8.93987 2.6784 8.3426 1.95611 7.5785 1.44783C6.8144 0.939546 5.9174 0.667838 4.99968 0.666687ZM6.99968 13.5867L5.29968 12.74C5.20663 12.6931 5.10388 12.6687 4.99968 12.6687C4.89549 12.6687 4.79274 12.6931 4.69968 12.74L2.99968 13.5867V9.54669C3.42163 9.74593 3.87107 9.88076 4.33302 9.94669V10.6667C4.33302 10.8435 4.40326 11.0131 4.52828 11.1381C4.6533 11.2631 4.82287 11.3334 4.99968 11.3334C5.1765 11.3334 5.34606 11.2631 5.47109 11.1381C5.59611 11.0131 5.66635 10.8435 5.66635 10.6667V9.94669C6.12829 9.88076 6.57774 9.74593 6.99968 9.54669V13.5867ZM4.99968 8.66669C4.34041 8.66669 3.69595 8.47119 3.14778 8.10492C2.59962 7.73865 2.17238 7.21805 1.92009 6.60896C1.66779 5.99988 1.60178 5.32966 1.7304 4.68305C1.85902 4.03645 2.17649 3.44251 2.64266 2.97633C3.10884 2.51016 3.70278 2.19269 4.34938 2.06407C4.99599 1.93545 5.66621 2.00146 6.2753 2.25376C6.88438 2.50605 7.40498 2.93329 7.77125 3.48145C8.13752 4.02962 8.33302 4.67408 8.33302 5.33335C8.33302 6.21741 7.98183 7.06525 7.35671 7.69038C6.73159 8.3155 5.88374 8.66669 4.99968 8.66669Z" fill="#00CDB6"/>
</svg>
</template>

<script>
export default {
  name: 'IconTimerDone',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
