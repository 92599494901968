<style lang="scss"></style>

<template>
  <div class="final__section mb-4 md:mx-2">
    <Task
      class="final__section-first mb-4 lg:mb-0"
      :image="'/images/task_bg.jpg'"
      :status="finalStatus"
      :timer="userStats.first_task_remained"
      mainTask
      :user="user"
      taskId="mpp"
      descr="Разработай модель по прогнозированию ошибки для модели оценки вероятности дефолта"
      :isAvailable="
        finalStatus &&
        (finalStatus.is_main_task_available || finalStatus.is_main_task_ended)
      "
      :isEnded="finalStatus && finalStatus.is_main_task_ended"
      :result="user.main_task_result"
      title="Задача MPP"
    >
      <SmallTimer :interval="userStats.first_task_remained" />
    </Task>
    <Task
      class="final__section-second"
      :image="'/images/task_bg-2.jpg'"
      :isAvailable="
        finalStatus &&
        (finalStatus.is_essay_task_available || finalStatus.is_essay_task_ended)
      "
      :isEnded="finalStatus && finalStatus.is_essay_task_ended"
      :user="user"
      descr="Найди ошибки в построенной модели прогнозирования факта сдачи теста"
      taskId="exam"
      title="Задача Exam"
    >
      <SmallTimer :interval="userStats.second_task_remained" />
    </Task>
  </div>
</template>

<script>
import SmallTimer from '@/components/Final/SmallTimer.vue'
import Task from '@/components/Final/Task.vue'

export default {
  name: 'FinalTasks',
  components: {
    SmallTimer,
    Task,
  },
  data() {
    return {}
  },
  props: {
    finalStatus: { type: Object },
    userStats: { type: Object },
    user: { type: Object },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
