<style lang="scss">
.final__description {
  color: #ffffff;
  &-block {
  }
  &-title {
    @apply font-druk_text_cy;
    display: block;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 40px;
  }
  &-subtitle {
    @apply font-graphik_lv_regular;
    display: block;
    font-size: 14px;
    line-height: 20px;
    max-width: 600px;
    span {
      color: #1b8cc5;
    }
  }
}
@screen lg {
  .final__description {
    &-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
    }
    &-title {
      margin: 0;
    }
    &-subtitle {
      margin-right: 100px;
    }
  }
}
</style>

<template>
  <div class="final__description">
    <div v-if="isFinalist" class="final__description-block">
      <p class="final__description-title">
        Время на выполнение задач
        <br />ограничено
      </p>
      <p class="final__description-subtitle">
        <span>Основную</span> задачу можно выполнить до
        <span>14:00</span> по МСК
        <span>31 октября</span>, а дополнительную до
        <span>19:00 30 октября</span>. Дополнительное задание необязательно к выполнению, однако два игрока решивших его наилучшим образом получат в награду
        <span>Apple Watch</span>!
      </p>
    </div>
    <div v-else class="final__description-block">
      <p class="final__description-title">
        Ты не прошел
        <br />отборочный тур
      </p>
      <p class="final__description-subtitle">
        Ты не можешь принять участие в финальной битве, но тебе доступны задания финала для личной практики. У тебя нет сроков поэтому можешь спокойно изучить материал. Не забудь про
        <span>стрим 31 Октября</span> в
        <span>14:00</span> там будет разбор задач и интересные спикеры.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Description',
  data() {
    return {}
  },
  props: {
    isFinalist: {
      default: false,
    },
  },
}
</script>
